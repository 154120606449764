<script>
import Multiselect from 'vue-multiselect';
import Swal from "sweetalert2";
import Operation from './operation.vue';
import SideButtons from '../../../../../components/side-buttons.vue';
import {getDropDownElementsByTypeApi} from "@/api/common";
export default {
    components:{Multiselect, Operation,SideButtons},
    props:['animal'],
    data(){
        return {
            add:false,
            info:true,
            save:false,
            saveToPending:false,
            confirm:false,
            validate:false,
            edit:false,
            select:false,
            destroy:false,
            resetForm:false,
            formData:{
                serial_number:"",
                animal_name:"",  
                age:"",           
                lifebase_id:"",   
                status:"",        
                branch:"",        
                class:"",         
                order:"",         
                family:"",        
                kind:"",          
                species:"",  
                diet:"",
                longevity:"",
                birth:"",    
                height:"",        
                size:"",          
                weight:"",        
                coat_color:"",    
                father:"",        
                mother:""      
            },
            lifebases:[],
            status:[
                {id:'alive',text:'Vivant'},
                // {id:'dead',text:'Mort'},
                {id:'new_born',text:'Nouveau Née'},
                // {id:'veterinary_check',text:'RDV Vétérinaire'},
                // {id:'slaughterhouse',text:'Abattu'},
            ],
            Embranchements:[],
            Classes:[],
            Ordres:[],
            Familles:[],
            Genres:[],
            Especes:[],
            Regimes:[],
            selectedBase:[],
            selectedBranch:[],
            selectedOrder:[],
            selectedClass:[],
            selectedFamily:[],
            selectedKind:[],
            selectedSpecies:[],
            selectedDiet:[],
            selectedStatus:[],
            disabled:true
        }
    },
    watch:{
        add:{
            handler(val){
                if(val){
                    console.log("clearForm");
                    this.disabled = false;
                    this.resetForm = false;
                    this.resetData();
                    this.edit=false;
                }
            }
        },
        edit:{
            handler(val){
                if(val){
                    this.disabled = false;
                    this.add=false;
                    this.resetForm=false;
                    this.status = []
                }
            }
        },
        destroy:{
            handler(val){
                if(this.animal[0].operations.length > 0){
                     Swal.fire(
                        'Interdit',
                        `Vous ne pouvez pas supprimer cet animal, plusieurs opérations ont été opéré sur lui`,
                        'error'
                    )
                }
                else {
                    this.destroyAnimal();
                }
            }
        },
        resetForm:{
            handler(val){
            console.log(val);
                if(val) {
                    console.log('reset');
                    this.resetData();
                    this.disabled=true;
                    this.add=false;
                    this.edit=false;
                    this.destroy=false;
                }
            }
        },
        animal:{
            handler(val){
                if(val.length > 0 && val[0].id){
                        this.formData = {
                            id:val[0].id,
                            serial_number:val[0].serial_number,
                            animal_name:val[0].animal_name,  
                            age:val[0].age,           
                            lifebase_id:val[0].lifebase_id,   
                            status: val[0].status ,        
                            branch:val[0].branch != null ? val[0].branch : "",        
                            class:val[0].class != null ? val[0].class : "",         
                            order:val[0].order != null ? val[0].order : "",         
                            family:val[0].family != null ? val[0].branch : "",        
                            kind:val[0].kind != null ? val[0].kind : "",          
                            species:val[0].species != null ? val[0].species.id : "",  
                            diet:val[0].diet != null ? val[0].diet : "",
                            longevity:val[0].longevity,
                            birth:val[0].birth,    
                            height:val[0].height,        
                            size:val[0].size,          
                            weight:val[0].weight,        
                            coat_color:val[0].coat_color,    
                            father:val[0].father != null ? val[0].father : "",        
                            mother:val[0].mother != null ? val[0].mother : ""  
                        };

                        this.select=true;

                    this.selectedBase.push(val[0].lifebase);
                    this.selectedStatus.push(this.status.filter(x=>(x.id == val[0].status))[0]);
                    if(val[0].branch != null) this.selectedBranch.push(val[0].branchs);
                    if(val[0].order != null) this.selectedOrder.push(val[0].orders);
                    if(val[0].class != null) this.selectedClass.push(val[0].classes);
                    if(val[0].family != null) this.selectedFamily.push(val[0].families);
                    if(val[0].kind != null) this.selectedKind.push(val[0].kinds);
                    if(val[0].species != null) this.selectedSpecies.push(val[0].speciess);
                    if(val[0].diet != null) this.selectedDiet.push(val[0].diets);
                }
                else {this.resetData();this.select = false}
            }
        }
    },
    mounted(){
        this.getLifeBases();
        this.getlistClassification();
    },

    methods:{
        baseID(base){
            this.formData.lifebase_id = base.id;
        },
        BranchID(branch){
            this.formData.branch = branch.id;
        },
        OrderID(order){
            this.formData.order = order.id;
        },
        ClassID(classe){
            this.formData.class = classe.id;
        },
        FamilyID(family){
            this.formData.family = family.id;
        },
        KindID(kind){
            this.formData.kind = kind.id;
        },
        SpeciesID(species){
            this.formData.species = species.id;
        },
        DietID(diet){
            this.formData.diet = diet.id;
        },
        statusID(status){
            this.formData.status = status.id;
        },

        newAnimal(){
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "d'enregistrer l'animal ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.post('/referentiel/zoo/store',this.formData)
                    .then(response => {
                        console.log(response);
                        this.$emit('refresh',true)
                        this.resetData();
                        this.add=false;
                        this.resetForm=true;
                        this.disabled=true;
                        this.$toast.success("l'animal "+this.formData.serial_number+" a été enregistré");
                        // Swal.fire("Félicitations!", "l'animal "+this.formData.serial_number+" a été enregistré", "success");
                    })
                    .catch(error => {
                        console.log(error);
                        // Swal.fire("Erreur!", "une erreur s'est produite, veuillez réessayer", "error");
                        this.$toast.error("Un probléme s'est produit, Veuillez réessayer");
                    })
                }
            })
        },
        updateAnimal(){
            Swal.fire({
                title: 'Êtes vous sûr ?',
                text: "de mettre à jour cet animal ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.post('/referentiel/zoo/update',this.formData)
                    .then(response => {
                        console.log(response);
                        this.$emit('refresh',true)
                        this.edit=false;
                        this.disabled=true;
                        this.$toast.success("les informations ont bien été enregistré");
                        // Swal.fire("Félicitations!", "l'animal "+this.formData.serial_number+" a été mise à jour", "success");

                    })
                    .catch(error =>{
                        console.log(error);
                        this.$toast.error("Un probléme s'est produit, Veuillez réessayer");

                    } )
                }
            })
            
        },
        destroyAnimal(){
            Swal.fire({
                title: 'Êtes vous sûr?',
                text: "Cette Action est irréversible !!!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2b8a3e',
                cancelButtonColor: '#d33',
                cencelButtonText: 'Annuler',
                confirmButtonText: 'Oui, Supprimer'
                }).then((result) => {
                if (result.isConfirmed) {
                    this.$http.post('/referentiel/zoo/destroy',this.formData)
                    .then(response => {
                        console.log(response);
                        this.$emit('refresh',true);
                        this.resetData();
                        this.disabled=true;
                        // Swal.fire("Félicitations!", "l'animal "+this.formData.serial_number+" a été supprimé", "success");
                        this.$toast.success( "L'animal "+this.formData.serial_number+" a été supprimé");
                        this.destroy=false;
                    })
                    .catch(error => console.log(error))
                }
            })
            
        },
        getLifeBases(){
            this.$http.post('/base/lifebases/list')
            .then(response => {
                this.lifebases = response.data.original.list;
                this.$emit('listBases',this.lifebases);
            })
            .catch(error => console.log(error))
        },
        getlistClassification(){
            getDropDownElementsByTypeApi("animal_branch")
            .then(res => {
                // console.log(res);
                this.Embranchements = res.data.original.list
            })
            .catch(error => (console.log(error)));

            getDropDownElementsByTypeApi("animal_class")
            .then(res => {
                // console.log(res);
                this.Classes = res.data.original.list
            })
            .catch(error => (console.log(error)));

            getDropDownElementsByTypeApi("animal_order")
            .then(res => {
                // console.log(res);
                this.Ordres = res.data.original.list
            })
            .catch(error => (console.log(error)));

            getDropDownElementsByTypeApi("animal_family")
            .then(res => {
                // console.log(res);
                this.Familles = res.data.original.list
            })
            .catch(error => (console.log(error)));

            getDropDownElementsByTypeApi("animal_kind")
            .then(res => {
                // console.log(res);
                this.Genres = res.data.original.list
            })
            .catch(error => (console.log(error)));

            getDropDownElementsByTypeApi("animal_species")
            .then(res => {
                // console.log(res);
                this.Especes = res.data.original.list
            })
            .catch(error => (console.log(error)));

            getDropDownElementsByTypeApi("animal_diet")
            .then(res => {
                // console.log(res);
                this.Regimes = res.data.original.list
            })
            .catch(error => (console.log(error)));
            
            getDropDownElementsByTypeApi("animal_status")
            .then(res => {
                // console.log(res);
                this.status = res.data.original.list
            })
            .catch(error => (console.log(error)));
        },
        resetData(){
            this.formData={
                serial_number:"",
                animal_name:"",  
                age:"",           
                lifebase_id:"",   
                status:"",        
                branch:"",        
                class:"",         
                order:"",         
                family:"",        
                kind:"",          
                species:"",  
                diet:"",
                longevity:"",
                birth:"",    
                height:"",        
                size:"",          
                weight:"",        
                coat_color:"",    
                father:"",        
                mother:""      
            };
            this.selectedBase=[];
            this.selectedBranch=[];
            this.selectedOrder=[];
            this.selectedClass=[];
            this.selectedFamily=[];
            this.selectedKind=[];
            this.selectedSpecies=[];
            this.selectedDiet=[];
            this.selectedStatus=[];

        }
    }
}
</script>
<template>
    <div class="p-2">
        <SideButtons v-if="info"  :reset="resetForm"
           :service_order="animal" @info ="info = $event" @add="add = $event" :canEdit="!select"
          @edit=" edit = $event" @resetEmit="resetForm = $event" @delete=" destroy = $event" />
        <div class="row mb-2">
            <div class="col">
                <b-tabs content-class="mt-3" fill justified>
                    <b-tab title="Fiche d'Information" active @click="info=true">
                        <fieldset :disabled="disabled">
                            <div class="row mb-2">
                                <div class="col">
                                    <label for="">N° ID</label>
                                    <input type="text" class="form-control" v-model="formData.serial_number">
                                    <!-- <multiselect v-model="value" :options="options"></multiselect> -->
                                </div>
                                <div class="col">
                                    <label for="">Animal</label>
                                    <input type="text" class="form-control" v-model="formData.animal_name">
                                    <!-- <multiselect v-model="value" :options="options"></multiselect> -->
                                </div>
                                <div class="col">
                                    <label for="">Age (ans)</label>
                                    <input type="number" min="0" class="form-control" v-model="formData.age">
                                    <!-- <multiselect v-model="value" :options="options"></multiselect> -->
                                </div>
                                <div class="col">
                                    <label for="">Base de vie</label>
                                    <!-- <input type="text" class="form-control"> -->
                                    <multiselect v-model="selectedBase" :class="{'disabledMS':disabled}" @select="baseID" track-by="id" label="name" :deselect-label="''" placeholder="" :select-label="''"  :searchable="false" :allow-empty="false" :options="lifebases"></multiselect>
                                </div>
                                <div class="col">
                                    <label for="">status</label>
                                    <!-- <input type="text" class="form-control"> -->
                                    <multiselect v-model="selectedStatus" :class="{'disabledMS':disabled}" @select="statusID" :options="status" track-by="id" :deselect-label="''" :select-label="''" placeholder=""  label="designation" :searchable="false" :allow-empty="false" :close-on-select="false" :show-labels="false"></multiselect>
                                </div>
                            </div>
                        </fieldset>
                        
                        

                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-1 style="background:#e5e9f0; color:black !important; border:none !important;" class="text-left">CLASSIFICATION</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row mb-2">
                                                <div class="col">
                                                    <label for="">Embranchement</label>
                                                    <!-- <input type="text" class="form-control"> -->
                                                    <multiselect v-model="selectedBranch" @select="BranchID" :class="{'disabledMS':disabled}" track-by="id" class="here" :deselect-label="''" :select-label="''" placeholder="" :disabled="disabled" label="designation" :searchable="false" :allow-empty="false" :options="Embranchements"></multiselect>
                                                </div>
                                                <div class="col">
                                                    <label for="">Classe</label>
                                                    <!-- <input type="number" min="0" class="form-control"> -->
                                                    <multiselect v-model="selectedClass" @select="ClassID" track-by="id" :class="{'disabledMS':disabled}" class="here" :deselect-label="''" :select-label="''" placeholder="" :disabled="disabled" label="designation" :searchable="false" :allow-empty="false" :options="Classes"></multiselect>
                                                </div>
                                                <div class="col">
                                                    <label for="">Ordre</label>
                                                    <!-- <input type="text" class="form-control"> -->
                                                    <multiselect v-model="selectedOrder" @select="OrderID" track-by="id" :class="{'disabledMS':disabled}" class="here" :deselect-label="''" :select-label="''" placeholder="" :disabled="disabled" label="designation" :searchable="false" :allow-empty="false" :options="Ordres"></multiselect>
                                                </div>
                                                <div class="col">
                                                    <label for="">Famille</label>
                                                    <!-- <input type="text" class="form-control"> -->
                                                    <multiselect v-model="selectedFamily" @select="FamilyID" track-by="id" :class="{'disabledMS':disabled}" class="here" :deselect-label="''" :select-label="''" placeholder="" :disabled="disabled" label="designation" :searchable="false" :allow-empty="false" :options="Familles"></multiselect>
                                                </div>
                                            </div>
                                            <div class="row mb-2 ">
                                                <div class="col">
                                                    <label for="">Genre</label>
                                                    <!-- <input type="text" class="form-control"> -->
                                                    <multiselect v-model="selectedKind" @select="KindID" track-by="id" :class="{'disabledMS':disabled}" class="here" :deselect-label="''" :select-label="''" placeholder="" :disabled="disabled" label="designation" :searchable="false" :allow-empty="false" :options="Genres"></multiselect>
                                                </div>
                                                <div class="col">
                                                    <label for="">Espèce</label>
                                                    <!-- <input type="number" min="0" class="form-control"> -->
                                                    <multiselect v-model="selectedSpecies" @select="SpeciesID" :class="{'disabledMS':disabled}" track-by="id" class="here" :deselect-label="''" :select-label="''" placeholder="" :disabled="disabled" label="designation" :searchable="false" :allow-empty="false" :options="Especes"></multiselect>
                                                </div>
                                                <div class="col">
                                                    <label for="">Longevité</label>
                                                    <input type="text" class="form-control" :disabled="disabled" v-model="formData.longevity">
                                                    <!-- <multiselect v-model="value" :options="lifebases"></multiselect> -->
                                                </div>
                                                <div class="col">
                                                    <label for="">Régime Alimentaire</label>
                                                    <!-- <input type="text" class="form-control"> -->
                                                    <multiselect v-model="selectedDiet" @select="DietID" :disabled="disabled" track-by="id" :class="{'disabledMS':disabled}" class="here" :deselect-label="''" :select-label="''" placeholder="" label="designation" :searchable="false" :allow-empty="false" :options="Regimes"></multiselect>
                                                </div>
                                                
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-2 style="background:#e5e9f0; color:black !important; border:none !important;" class="text-left">CARACTERISTIQUES PHYSIQUES </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>
                                            <div class="row mb-2">
                                                <div class="col">
                                                    <label for="">Taille (cm)</label>
                                                    <input type="number" min="0" :disabled="disabled" class="form-control" v-model="formData.size">
                                                    <!-- <multiselect v-model="value" :options="Embranchements"></multiselect> -->
                                                </div>
                                                <div class="col">
                                                    <label for="">Hauteur (cm)</label>
                                                    <input type="number" min="0" :disabled="disabled" class="form-control" v-model="formData.height">
                                                    <!-- <multiselect v-model="value" :options="Embranchements"></multiselect> -->
                                                </div>
                                                <div class="col">
                                                    <label for="">Poids (kg)</label>
                                                    <input type="number" min="0" :disabled="disabled" class="form-control" v-model="formData.weight">
                                                    <!-- <multiselect v-model="value" :options="Classes"></multiselect> -->
                                                </div>
                                                <div class="col">
                                                    <label for="">Date de Naissance</label>
                                                    <input type="date" :disabled="disabled" class="form-control" v-model="formData.birth">
                                                    <!-- <multiselect v-model="value" :options="Ordres"></multiselect> -->
                                                </div>
                                                <div class="col">
                                                    <label for="">Couleur Pelage</label>
                                                    <input type="text" :disabled="disabled" class="form-control" v-model="formData.coat_color">
                                                    <!-- <multiselect v-model="value" :options="Ordres"></multiselect> -->
                                                </div>
                                                
                                            </div>
                                            
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                        <div class="row mt-3">
                            <div class="col text-right">
                                <b-button v-if="animal.length > 0 && edit"  @click="updateAnimal" variant="primary" class="btn-label mr-2">
                                    <i class="far fa-save text-white label-icon"></i> Enregitrer
                                </b-button>
                                <b-button v-if="add"  @click="newAnimal" variant="primary" class="btn-label mr-2">
                                    <i class="far fa-save text-white label-icon"></i> Enregitrer
                                </b-button>

                                <b-button v-if="add || edit"  @click="resetForm = true" variant="light" class="btn-label mr-2">
                                    <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                                </b-button>


                            </div>
                        </div>
                    </b-tab>

                    <b-tab title="Opérations" @click="info=false">
                        <operation :animal="animal"></operation>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
<style>
.here .multiselect__content-wrapper{
    position:relative !important;
    max-height: 300px !important;
}
</style>